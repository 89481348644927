// Import dependencies
import { GestureRecognizer, FilesetResolver, DrawingUtils } from '@mediapipe/tasks-vision';
import { AppContext } from 'context/appContext';
import { useContext, useEffect, useRef } from 'react';
import './index.scss';

type GestureDetectionCallbacks = {};
type GestureDetectionOptions = {
	landmarksOverlay?: boolean;
};

class _GESTURE_DETECTION_CONTROLLER {
	private detectionIntervalId: ReturnType<typeof setInterval>;
	private zoomedOut: boolean = true;
	private gestureRecognizer: GestureRecognizer;
	private sequenceNumber: number;

	public drawRect = (detections: any, ctx: any) => {
		const drawingUtils = new DrawingUtils(ctx);
		if (detections && detections.landmarks) {
			for (const landmarks of detections.landmarks) {
				drawingUtils.drawConnectors(landmarks, GestureRecognizer.HAND_CONNECTIONS, {
					color: '#00FF00',
					lineWidth: 5,
				});
				drawingUtils.drawLandmarks(landmarks, {
					color: '#FF0000',
					lineWidth: 2,
				});
			}
		}
	};

	public detect = async () => {
		let results = undefined;
		let nowInMs = Date.now();
		if (this.video.currentTime !== this.lastVideoTime) {
			this.lastVideoTime = this.video.currentTime;
			results = this.gestureRecognizer.recognizeForVideo(this.video, nowInMs);
		}

		if (results && results.gestures.length > 0) {
			// console.log('Gestures detected:' + results.gestures.length);
			// console.log(results.gestures);
			for (let index = 0; index < results.gestures.length; index++) {
				const categoryName = results.gestures[index][0].categoryName;
				const handedness = results.handedness[index][0].displayName;
				this.publish(categoryName, handedness, results.gestures[0][0].score * 100, nowInMs);
			}
		}

		const ctx = this.canvasRef?.current?.getContext('2d');
		ctx?.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
		if (this.options.landmarksOverlay) {
			// Draw mesh
			this.drawRect(results, ctx);
		}
	};

	public load = async () => {
		// Create task for image file processing:
		const vision = await FilesetResolver.forVisionTasks(
			// path/to/wasm/root
			'https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@latest/wasm'
		);
		this.gestureRecognizer = await GestureRecognizer.createFromOptions(vision, {
			baseOptions: {
				modelAssetPath:
					'https://storage.googleapis.com/mediapipe-tasks/gesture_recognizer/gesture_recognizer.task',
				delegate: 'GPU',
			},
			numHands: 2,
			runningMode: 'VIDEO',
		});
		this.sequenceNumber = 0;
		this.start();
	};

	public start = () => {
		if (this.dimensions.width > 0 && this.dimensions.height > 0) {
			clearInterval(this.detectionIntervalId!);
			this.detectionIntervalId = setInterval(() => {
				requestAnimationFrame(() => this.detect());
			}, this.detectionRate);
			console.log('Gesture detection model started');
		}
	};

	public unload = () => {
		clearInterval(this.detectionIntervalId!);
		this.gestureRecognizer.close();
		this.sequenceNumber = 0;
		console.log('Gesture detection model unloaded');
	};

	public publish = (
		gesture: string,
		handedness: string,
		confidence_score: number,
		timestamp: number
	) => {
		if (
			(window as any).webRTCSession &&
			(window as any).webRTCSession.dataChannels[1] &&
			(window as any).webRTCSession.dataChannels[1].readyState === 'open'
		) {
			let message = JSON.stringify({
				type: 'gesture_update',
				data: {
					sequence_number: this.sequenceNumber,
					gesture: gesture,
					handedness: handedness,
					confidence_score: confidence_score,
					timestamp: timestamp,
				},
			});
			console.log('Publishing to datachannel: ' + message);
			(window as any).webRTCSession.dataChannels[1].send(message);
			this.sequenceNumber = this.sequenceNumber + 1;
		}
	};

	constructor(
		public video: any,
		public dimensions: { width: number; height: number },
		public canvasRef: React.RefObject<HTMLCanvasElement>,
		public callbacks: GestureDetectionCallbacks,
		public options: GestureDetectionOptions,
		public detectionRate: number = 150,
		public lastVideoTime = -1,
		public results = undefined
	) {}
}

type PropsFromParent = {
	video: any;
	dimensions: { width: number; height: number };
	callbacks: GestureDetectionCallbacks;
	options: GestureDetectionOptions;
};

const GestureDetection: React.FC<PropsFromParent> = ({ video, dimensions, callbacks, options }) => {
	const { settingsController } = useContext(AppContext);
	const getLibraryController = (library: string) => {
		return _GESTURE_DETECTION_CONTROLLER;
	};
	let libraryController = getLibraryController('library');

	const canvasRef = useRef<HTMLCanvasElement>(null);
	// a ref, because we don't ever change the created instance
	const controller = useRef(
		new libraryController!(video, dimensions, canvasRef, callbacks, options)
	);

	useEffect(() => {
		controller.current.load();

		return () => {
			controller.current.unload();
		};
	}, []);

	useEffect(() => {
		controller.current.options = options;
		controller.current.callbacks = callbacks;
	}, [options, callbacks]);

	return video && dimensions.width > 0 && dimensions.height > 0 ? (
		<canvas
			ref={canvasRef}
			className="detectionContainer"
			width={dimensions.width}
			height={dimensions.height}
		/>
	) : null;
};

export default GestureDetection;
