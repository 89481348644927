import React, { useContext, useEffect, useMemo, useState, useRef, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { ConnectedProps, connect } from 'react-redux';
import './index.scss';

import { StatMode } from 'GoBeWebRTC/hooks/useStats';
import { setParameter } from 'actions/setParam';
import BetaLabel from 'components/betaLabel';
import FeedbackCard from 'components/feedbackCard';
import PatternPicker from 'components/patternPicker';
import Select, { SelectValue } from 'components/select';
import ToggleButton from 'components/toggleButton';
import { AppContext } from 'context/appContext';
import { Feedbackable } from 'hooks/useFeedbackController';
import { useOutsideAlerter } from 'hooks/useOutsideAlerter';
import {
	Setting,
	SettingHeaders,
	SettingPage,
	SettingPageHeaders,
	SettingPageSection,
	SettingPageSectionHeaders,
	SettingSectionHeaders,
	SettingTab,
	SettingTabHeaders,
} from 'hooks/useSettingsController';
import { ObjectDetectionLibraries } from 'pages/session/videos/objectDetection';
import { ObjectSegmentationLibraries } from 'pages/session/videos/objectSegmentation';
import { AppRootState } from 'reducers';
import { DragModes } from 'types';
import { feedbackNameToSettingHeader } from 'utils/feedbackNameToSettingHeader';
import { HardwareState } from '../../reducers/hardwareReducer';
import { translationWithErrorHandling } from '../../translation/handleMissingTranslation';

const reduxConnector = connect((state: AppRootState) => ({}), { setParameter });

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
type ComponentProps = PropsFromRedux;
type CameraDevice = NonNullable<HardwareState['settings']['camera']>;
type MicrophoneDevice = NonNullable<HardwareState['settings']['microphone']>;
type SpeakersDevice = NonNullable<HardwareState['settings']['speakers']>;

const Settings: React.FC<ComponentProps> = ({}: ComponentProps) => {
	const intl = useIntl();
	const { settingsController, feedbackController } = useContext(AppContext);
	const [selectedSettingPageSection, setSelectedSettingPageSection] = useState<SettingPageSection>(
		settingsController.settings[SettingPageSectionHeaders.APP_SETTINGS]
	);
	const [selectedSettingPage, setSelectedSettingPage] = useState<SettingPage>(
		settingsController.settings[SettingPageSectionHeaders.APP_SETTINGS].children[
			SettingPageHeaders.APPEARANCE
		]
	);
	const [selectedTab, setSelectedTab] = useState<SettingTab | null>(null);
	const [microphonesList, setMicrophonesList] = useState<MicrophoneDevice[]>([]);
	const [speakersList, setSpeakersList] = useState<SpeakersDevice[]>([]);
	const [camerasList, setCamerasList] = useState<CameraDevice[]>([]);

	const onPageClick = (pageSection: SettingPageSection, page: SettingPage) => {
		if (selectedSettingPageSection !== pageSection || selectedSettingPage !== page) {
			setSelectedSettingPageSection(pageSection);
			setSelectedSettingPage(page);
		}
	};
	const onTabClick = (tab: SettingTab) => setSelectedTab(tab);
	const parsedFeedbackables = feedbackController.feedbackables.map((feedbackable) => ({
		...feedbackable,
		initialDelay: 0,
		timeout: 0,
		muteSound: true,
		settingHeader: feedbackNameToSettingHeader(feedbackable.name),
	}));
	let parsedFeedbackable;
	const tabContextRef = useRef(null);

	const innerContainerRef = useRef(null);
	useOutsideAlerter(innerContainerRef, settingsController.toggle);

	function capitalizeFirstLetter(string: string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	const renderSetting = (
		setting: Setting,
		callbacks?: {
			[name: string]: (...args: any[]) => any;
		}
	) => {
		switch (setting.header) {
			// case SettingHeaders.REMOTE_CAM_SKIN:
			// 	return (
			// 		<PatternPicker
			// 			selectedPatternName={
			// 				settingsController.settings[SettingPageSectionHeaders.APP_SETTINGS].children[
			// 					SettingPageHeaders.APPEARANCE
			// 				].children[SettingTabHeaders.GENERAL].children[SettingSectionHeaders.SKIN].children[
			// 					SettingHeaders.REMOTE_CAM_SKIN
			// 				].value
			// 			}
			// 			onPatternSelect={(pattern) =>
			// 				settingsController.setSettingValue(SettingHeaders.REMOTE_CAM_SKIN, pattern)
			// 			}
			// 		/>
			// 	);
			// case SettingHeaders.NAV_CAM_SKIN:
			// 	return (
			// 		<PatternPicker
			// 			selectedPatternName={
			// 				settingsController.settings[SettingPageSectionHeaders.APP_SETTINGS].children[
			// 					SettingPageHeaders.APPEARANCE
			// 				].children[SettingTabHeaders.GENERAL].children[SettingSectionHeaders.SKIN].children[
			// 					SettingHeaders.NAV_CAM_SKIN
			// 				].value
			// 			}
			// 			onPatternSelect={(pattern) =>
			// 				settingsController.setSettingValue(SettingHeaders.NAV_CAM_SKIN, pattern)
			// 			}
			// 		/>
			// 	);
			// case SettingHeaders.SHOW_AURA:
			// 	return (
			// 		<ToggleButton
			// 			value={
			// 				settingsController.settings[SettingPageSectionHeaders.APP_SETTINGS].children[
			// 					SettingPageHeaders.APPEARANCE
			// 				].children[SettingTabHeaders.GENERAL].children[SettingSectionHeaders.AURA].children[
			// 					SettingHeaders.SHOW_AURA
			// 				].value
			// 			}
			// 			onChange={(value) =>
			// 				settingsController.setSettingValue(SettingHeaders.SHOW_AURA, value)
			// 			}
			// 		/>
			// 	);
			case SettingHeaders.AR_ENABLE_NAV_LINES:
				return (
					<div className="setting-msg-container">
						<ToggleButton
							value={
								settingsController.settings[SettingPageSectionHeaders.APP_SETTINGS].children[
									SettingPageHeaders.AUGMENTED_REALITY
								].children[SettingTabHeaders.GENERAL].children[
									SettingSectionHeaders.AUGMENTED_REALITY
								].children[SettingHeaders.AR_ENABLE_NAV_LINES].value
							}
							onChange={(value) =>
								settingsController.setSettingValue(SettingHeaders.AR_ENABLE_NAV_LINES, value)
							}
							disabled={(window as any).webGlCrashed}
						/>
						{(window as any).webGlCrashed && (
							<b className="setting-msg-container-disabled-text">
								{translationWithErrorHandling(intl, 'settings.unableToDisplayNavLines')}
							</b>
						)}
					</div>
				);
			case SettingHeaders.AR_INVERT_BACKWARD_NAV_LINES:
				return (
					<ToggleButton
						value={
							settingsController.settings[SettingPageSectionHeaders.APP_SETTINGS].children[
								SettingPageHeaders.AUGMENTED_REALITY
							].children[SettingTabHeaders.GENERAL].children[
								SettingSectionHeaders.AUGMENTED_REALITY
							].children[SettingHeaders.AR_INVERT_BACKWARD_NAV_LINES].value
						}
						onChange={(value) =>
							settingsController.setSettingValue(SettingHeaders.AR_INVERT_BACKWARD_NAV_LINES, value)
						}
					/>
				);

			case SettingHeaders.URL_SHARING_ENABLED:
				return (
					<ToggleButton
						value={
							settingsController.settings[SettingPageSectionHeaders.EXPERIMENTAL].children[
								SettingPageHeaders.URL_SHARING
							].children[SettingTabHeaders.GENERAL].children[SettingSectionHeaders.URL_SHARING]
								.children[SettingHeaders.URL_SHARING_ENABLED].value
						}
						onChange={(value) =>
							settingsController.setSettingValue(SettingHeaders.URL_SHARING_ENABLED, value)
						}
					/>
				);
			case SettingHeaders.FEEDBACK_GENERAL:
				return (
					<ToggleButton
						value={
							settingsController.settings[SettingPageSectionHeaders.APP_SETTINGS].children[
								SettingPageHeaders.FEEDBACK
							].children[SettingTabHeaders.GENERAL].children[SettingSectionHeaders.FEEDBACKS]
								.children[SettingHeaders.FEEDBACK_GENERAL].value
						}
						onChange={(value) =>
							settingsController.setSettingValue(SettingHeaders.FEEDBACK_GENERAL, value)
						}
					/>
				);
			case SettingHeaders.FEEDBACK_SESSION_END:
				parsedFeedbackable = parsedFeedbackables.find(
					(feedbackable) => feedbackable.settingHeader === SettingHeaders.FEEDBACK_SESSION_END
				);
				return (
					<div className="innerSetting">
						<ToggleButton
							value={
								settingsController.settings[SettingPageSectionHeaders.APP_SETTINGS].children[
									SettingPageHeaders.FEEDBACK
								].children[SettingTabHeaders.GENERAL].children[SettingSectionHeaders.FEEDBACKS]
									.children[SettingHeaders.FEEDBACK_SESSION_END].value
							}
							onChange={(value) =>
								settingsController.setSettingValue(SettingHeaders.FEEDBACK_SESSION_END, value)
							}
							disabled={
								!settingsController.settings[SettingPageSectionHeaders.APP_SETTINGS].children[
									SettingPageHeaders.FEEDBACK
								].children[SettingTabHeaders.GENERAL].children[SettingSectionHeaders.FEEDBACKS]
									.children[SettingHeaders.FEEDBACK_GENERAL].value
							}
						/>
						<div className="innerSettingDisabled">
							<FeedbackCard
								feedbackable={parsedFeedbackable as Feedbackable}
								onDismiss={() => {}}
								onSubmit={(rating, review) => {}}
							/>
						</div>
					</div>
				);
			case SettingHeaders.FEEDBACK_SWITCH:
				parsedFeedbackable = parsedFeedbackables.find(
					(feedbackable) => feedbackable.settingHeader === SettingHeaders.FEEDBACK_SWITCH
				);
				return (
					<div className="innerSetting">
						<ToggleButton
							value={
								settingsController.settings[SettingPageSectionHeaders.APP_SETTINGS].children[
									SettingPageHeaders.FEEDBACK
								].children[SettingTabHeaders.GENERAL].children[SettingSectionHeaders.FEEDBACKS]
									.children[SettingHeaders.FEEDBACK_SWITCH].value
							}
							onChange={(value) =>
								settingsController.setSettingValue(SettingHeaders.FEEDBACK_SWITCH, value)
							}
							disabled={
								!settingsController.settings[SettingPageSectionHeaders.APP_SETTINGS].children[
									SettingPageHeaders.FEEDBACK
								].children[SettingTabHeaders.GENERAL].children[SettingSectionHeaders.FEEDBACKS]
									.children[SettingHeaders.FEEDBACK_GENERAL].value
							}
						/>
						<div className="innerSettingDisabled">
							<FeedbackCard
								feedbackable={parsedFeedbackable as Feedbackable}
								onDismiss={() => {}}
								onSubmit={(rating, review) => {}}
							/>
						</div>
					</div>
				);
			case SettingHeaders.DRAG_MODE:
				return (
					<Select
						keyPrefix="dragMode"
						values={Object.values(DragModes).map(
							(mode) =>
								({
									name: (() =>
										translationWithErrorHandling(intl, `settings.${mode.toLowerCase()}`))() as any,
									value: mode,
								} as SelectValue)
						)}
						selectedValue={{
							name: (() => {
								switch (
									settingsController.settings[SettingPageSectionHeaders.EXPERIMENTAL].children[
										SettingPageHeaders.APPEARANCE
									].children[SettingTabHeaders.GENERAL].children[SettingSectionHeaders.MISC]
										.children[SettingHeaders.DRAG_MODE].value
								) {
									case DragModes.DISABLED:
										return translationWithErrorHandling(intl, 'settings.disabled');
									case DragModes.HANDLE:
										return translationWithErrorHandling(intl, 'settings.handle');
									case DragModes.DRAGGABLE:
										return translationWithErrorHandling(intl, 'settings.draggable');
									default:
										return 'MISSING TRANSLATION';
								}
							})() as any,
							value: capitalizeFirstLetter(
								settingsController.settings[SettingPageSectionHeaders.EXPERIMENTAL].children[
									SettingPageHeaders.APPEARANCE
								].children[SettingTabHeaders.GENERAL].children[SettingSectionHeaders.MISC].children[
									SettingHeaders.DRAG_MODE
								].value
							),
						}}
						onChange={(data) =>
							settingsController.setSettingValue(
								SettingHeaders.DRAG_MODE,
								capitalizeFirstLetter(data.value)
							)
						}
					/>
				);
			case SettingHeaders.OBJECT_DETECTION_LIBRARY:
				return (
					<Select
						keyPrefix="objectDetectionLibrary"
						values={Object.values(ObjectDetectionLibraries).map(
							(lib) => ({ name: lib, value: lib } as SelectValue)
						)}
						selectedValue={{
							name: settingsController.settings[SettingPageSectionHeaders.EXPERIMENTAL].children[
								SettingPageHeaders.IMAGE_RECOGNITION
							].children[SettingTabHeaders.OBJECT_DETECTION].children[
								SettingSectionHeaders.DETECTION
							].children[SettingHeaders.OBJECT_DETECTION_LIBRARY].value,
							value:
								settingsController.settings[SettingPageSectionHeaders.EXPERIMENTAL].children[
									SettingPageHeaders.IMAGE_RECOGNITION
								].children[SettingTabHeaders.OBJECT_DETECTION].children[
									SettingSectionHeaders.DETECTION
								].children[SettingHeaders.OBJECT_DETECTION_LIBRARY].value,
						}}
						onChange={(data) =>
							settingsController.setSettingValue(
								SettingHeaders.OBJECT_DETECTION_LIBRARY,
								data.value
							)
						}
					/>
				);
			case SettingHeaders.ENABLE_OBJECT_DETECTION:
				return (
					<ToggleButton
						value={
							settingsController.settings[SettingPageSectionHeaders.EXPERIMENTAL].children[
								SettingPageHeaders.IMAGE_RECOGNITION
							].children[SettingTabHeaders.OBJECT_DETECTION].children[
								SettingSectionHeaders.DETECTION
							].children[SettingHeaders.ENABLE_OBJECT_DETECTION].value
						}
						onChange={(value) =>
							settingsController.setSettingValue(SettingHeaders.ENABLE_OBJECT_DETECTION, value)
						}
					/>
				);
			case SettingHeaders.ENABLE_GESTURE_DETECTION:
				return (
					<ToggleButton
						value={
							settingsController.settings[SettingPageSectionHeaders.EXPERIMENTAL].children[
								SettingPageHeaders.IMAGE_RECOGNITION
							].children[SettingTabHeaders.GESTURE_DETECTION].children[
								SettingSectionHeaders.DETECTION
							].children[SettingHeaders.ENABLE_GESTURE_DETECTION].value
						}
						onChange={(value) =>
							settingsController.setSettingValue(SettingHeaders.ENABLE_GESTURE_DETECTION, value)
						}
					/>
				);
			case SettingHeaders.ENABLE_GESTURE_LANDMARKS_OVERLAY:
				return (
					<ToggleButton
						value={
							settingsController.settings[SettingPageSectionHeaders.EXPERIMENTAL].children[
								SettingPageHeaders.IMAGE_RECOGNITION
							].children[SettingTabHeaders.GESTURE_DETECTION].children[
								SettingSectionHeaders.DETECTION
							].children[SettingHeaders.ENABLE_GESTURE_LANDMARKS_OVERLAY].value
						}
						onChange={(value) =>
							settingsController.setSettingValue(
								SettingHeaders.ENABLE_GESTURE_LANDMARKS_OVERLAY,
								value
							)
						}
					/>
				);
			case SettingHeaders.ENABLE_OBJECT_DETECTION_FACE_TRACKING:
				return (
					<ToggleButton
						value={
							settingsController.settings[SettingPageSectionHeaders.EXPERIMENTAL].children[
								SettingPageHeaders.IMAGE_RECOGNITION
							].children[SettingTabHeaders.OBJECT_DETECTION].children[
								SettingSectionHeaders.DETECTION
							].children[SettingHeaders.ENABLE_OBJECT_DETECTION_FACE_TRACKING].value
						}
						onChange={(value) =>
							settingsController.setSettingValue(
								SettingHeaders.ENABLE_OBJECT_DETECTION_FACE_TRACKING,
								value
							)
						}
					/>
				);
			case SettingHeaders.OBJECT_SEGMENTATION_LIBRARY:
				return (
					<Select
						keyPrefix="objectSegmentationLibrary"
						values={Object.values(ObjectSegmentationLibraries).map(
							(lib) => ({ name: lib, value: lib } as SelectValue)
						)}
						selectedValue={{
							name: settingsController.settings[SettingPageSectionHeaders.EXPERIMENTAL].children[
								SettingPageHeaders.IMAGE_RECOGNITION
							].children[SettingTabHeaders.OBJECT_SEGMENTATION].children[
								SettingSectionHeaders.SEGMENTATION
							].children[SettingHeaders.OBJECT_SEGMENTATION_LIBRARY].value,
							value:
								settingsController.settings[SettingPageSectionHeaders.EXPERIMENTAL].children[
									SettingPageHeaders.IMAGE_RECOGNITION
								].children[SettingTabHeaders.OBJECT_SEGMENTATION].children[
									SettingSectionHeaders.SEGMENTATION
								].children[SettingHeaders.OBJECT_SEGMENTATION_LIBRARY].value,
						}}
						onChange={(data) =>
							settingsController.setSettingValue(
								SettingHeaders.OBJECT_SEGMENTATION_LIBRARY,
								data.value
							)
						}
					/>
				);
			case SettingHeaders.ENABLE_SELFIE_SEGMENTATION:
				return (
					<ToggleButton
						value={
							settingsController.settings[SettingPageSectionHeaders.EXPERIMENTAL].children[
								SettingPageHeaders.IMAGE_RECOGNITION
							].children[SettingTabHeaders.OBJECT_SEGMENTATION].children[
								SettingSectionHeaders.SEGMENTATION
							].children[SettingHeaders.ENABLE_SELFIE_SEGMENTATION].value
						}
						onChange={(value) =>
							settingsController.setSettingValue(SettingHeaders.ENABLE_SELFIE_SEGMENTATION, value)
						}
					/>
				);
			case SettingHeaders.ENABLE_CAMERA_CALIBRATION_TOOL:
				return (
					<ToggleButton
						value={
							settingsController.settings[SettingPageSectionHeaders.ADMIN].children[
								SettingPageHeaders.TOOLS
							].children[SettingTabHeaders.GENERAL].children[SettingSectionHeaders.MISC].children[
								SettingHeaders.ENABLE_CAMERA_CALIBRATION_TOOL
							].value
						}
						onChange={(value) =>
							settingsController.setSettingValue(
								SettingHeaders.ENABLE_CAMERA_CALIBRATION_TOOL,
								value
							)
						}
					/>
				);
			case SettingHeaders.MOTION_ENCODERS:
				return (
					<ToggleButton
						value={
							settingsController.settings[SettingPageSectionHeaders.ADMIN].children[
								SettingPageHeaders.TOOLS
							].children[SettingTabHeaders.GENERAL].children[SettingSectionHeaders.MISC].children[
								SettingHeaders.MOTION_ENCODERS
							].value
						}
						onChange={(value) =>
							settingsController.setSettingValue(SettingHeaders.MOTION_ENCODERS, value)
						}
					/>
				);

			case SettingHeaders.BITRATE_TO_REQUEST:
				return (
					<Select
						keyPrefix="bitrateToRequest"
						values={[1, 2, 3, 4].map((num) => ({
							name: num.toString(),
							value: num,
						}))}
						selectedValue={{
							name: settingsController.settings[SettingPageSectionHeaders.ADMIN].children[
								SettingPageHeaders.TOOLS
							].children[SettingTabHeaders.GENERAL].children[SettingSectionHeaders.MISC].children[
								SettingHeaders.BITRATE_TO_REQUEST
							].value.toString(),
							value:
								settingsController.settings[SettingPageSectionHeaders.ADMIN].children[
									SettingPageHeaders.TOOLS
								].children[SettingTabHeaders.GENERAL].children[SettingSectionHeaders.MISC].children[
									SettingHeaders.BITRATE_TO_REQUEST
								].value,
						}}
						onChange={(data) => {
							settingsController.setSettingValue(SettingHeaders.BITRATE_TO_REQUEST, data.value);
						}}
					/>
				);

			case SettingHeaders.STAT_MODE:
				return (
					<Select
						keyPrefix="statMode"
						values={Object.values(StatMode).map(
							(mode) =>
								({
									name: (() =>
										translationWithErrorHandling(intl, `settings.${mode.toLowerCase()}`))() as any,
									value: mode,
								} as SelectValue)
						)}
						selectedValue={{
							name: (() => {
								switch (
									settingsController.settings[SettingPageSectionHeaders.ADMIN].children[
										SettingPageHeaders.TOOLS
									].children[SettingTabHeaders.GENERAL].children[SettingSectionHeaders.MISC]
										.children[SettingHeaders.STAT_MODE].value
								) {
									case StatMode.DEFAULT:
										return translationWithErrorHandling(intl, 'settings.default');
									case StatMode.INTENSIVE:
										return translationWithErrorHandling(intl, 'settings.intensive');
									default:
										return 'MISSING TRANSLATION';
								}
							})() as any,

							value:
								settingsController.settings[SettingPageSectionHeaders.ADMIN].children[
									SettingPageHeaders.TOOLS
								].children[SettingTabHeaders.GENERAL].children[SettingSectionHeaders.MISC].children[
									SettingHeaders.STAT_MODE
								].value,
						}}
						onChange={(data) =>
							settingsController.setSettingValue(SettingHeaders.STAT_MODE, data.value)
						}
					/>
				);
			case SettingHeaders.RESET:
				return (
					<div className="settingsButton" onClick={callbacks?.reset}>
						{translationWithErrorHandling(intl, 'settings.reset').toUpperCase()}
					</div>
				);
			case SettingHeaders.MICROPHONE:
				const audioSettings =
					settingsController.settings[SettingPageSectionHeaders.APP_SETTINGS]?.children[
						SettingPageHeaders.AUDIOVIDEO
					]?.children[SettingTabHeaders.GENERAL]?.children[SettingSectionHeaders.AUDIOVIDEO]
						?.children[SettingHeaders.MICROPHONE]?.value;

				return (
					<Select
						key="microphoneName"
						keyPrefix="microphoneName"
						onChange={(data) => {
							settingsController.setSettingValue(SettingHeaders.MICROPHONE, data);
						}}
						values={microphonesList.map(({ id, name }) => {
							return {
								name: name,
								value: id,
							};
						})}
						selectedValue={
							audioSettings
								? {
										name:
											settingsController.settings[SettingPageSectionHeaders.APP_SETTINGS].children[
												SettingPageHeaders.AUDIOVIDEO
											].children[SettingTabHeaders.GENERAL].children[
												SettingSectionHeaders.AUDIOVIDEO
											].children[SettingHeaders.MICROPHONE].value.name ?? '',
										value:
											settingsController.settings[SettingPageSectionHeaders.APP_SETTINGS].children[
												SettingPageHeaders.AUDIOVIDEO
											].children[SettingTabHeaders.GENERAL].children[
												SettingSectionHeaders.AUDIOVIDEO
											].children[SettingHeaders.MICROPHONE].value.value ?? '',
								  }
								: settingsController.getDefaultSetting(SettingHeaders.MICROPHONE)
						}
					/>
				);
			case SettingHeaders.SPEAKERS:
				const speakersSettings =
					settingsController.settings[SettingPageSectionHeaders.APP_SETTINGS]?.children[
						SettingPageHeaders.AUDIOVIDEO
					]?.children[SettingTabHeaders.GENERAL]?.children[SettingSectionHeaders.AUDIOVIDEO]
						?.children[SettingHeaders.SPEAKERS]?.value;

				return (
					<Select
						key="SpeakersName"
						keyPrefix="SpeakersName"
						onChange={(data) => {
							settingsController.setSettingValue(SettingHeaders.SPEAKERS, data);
						}}
						values={speakersList.map(({ id, name }) => {
							return {
								name: name,
								value: id,
							};
						})}
						selectedValue={
							speakersSettings
								? {
										name:
											settingsController.settings[SettingPageSectionHeaders.APP_SETTINGS].children[
												SettingPageHeaders.AUDIOVIDEO
											].children[SettingTabHeaders.GENERAL].children[
												SettingSectionHeaders.AUDIOVIDEO
											].children[SettingHeaders.SPEAKERS].value.name ?? '',
										value:
											settingsController.settings[SettingPageSectionHeaders.APP_SETTINGS].children[
												SettingPageHeaders.AUDIOVIDEO
											].children[SettingTabHeaders.GENERAL].children[
												SettingSectionHeaders.AUDIOVIDEO
											].children[SettingHeaders.SPEAKERS].value.value ?? '',
								  }
								: settingsController.getDefaultSetting(SettingHeaders.SPEAKERS)
						}
					/>
				);
			case SettingHeaders.CAMERA:
				const videoSettings =
					settingsController.settings[SettingPageSectionHeaders.APP_SETTINGS]?.children[
						SettingPageHeaders.AUDIOVIDEO
					]?.children[SettingTabHeaders.GENERAL]?.children[SettingSectionHeaders.AUDIOVIDEO]
						?.children[SettingHeaders.CAMERA]?.value;
				return (
					<Select
						key="cameraName"
						keyPrefix="cameraName"
						onChange={(data) => {
							settingsController.setSettingValue(SettingHeaders.CAMERA, data);
						}}
						values={camerasList.map(({ id, name }) => {
							return {
								name: name,
								value: id,
							};
						})}
						selectedValue={
							videoSettings
								? {
										name:
											settingsController.settings[SettingPageSectionHeaders.APP_SETTINGS].children[
												SettingPageHeaders.AUDIOVIDEO
											].children[SettingTabHeaders.GENERAL].children[
												SettingSectionHeaders.AUDIOVIDEO
											].children[SettingHeaders.CAMERA].value.name ?? '',
										value:
											settingsController.settings[SettingPageSectionHeaders.APP_SETTINGS].children[
												SettingPageHeaders.AUDIOVIDEO
											].children[SettingTabHeaders.GENERAL].children[
												SettingSectionHeaders.AUDIOVIDEO
											].children[SettingHeaders.CAMERA].value.value ?? '',
								  }
								: undefined
						}
					/>
				);
			case SettingHeaders.ENABLE_DIGITAL_ZOOM:
				return (
					<ToggleButton
						value={
							settingsController.settings[SettingPageSectionHeaders.APP_SETTINGS]?.children[
								SettingPageHeaders.AUGMENTED_REALITY
							]?.children[SettingTabHeaders.GENERAL]?.children[
								SettingSectionHeaders.AUGMENTED_REALITY
							]?.children[SettingHeaders.ENABLE_DIGITAL_ZOOM]?.value
						}
						onChange={(value) =>
							settingsController.setSettingValue(SettingHeaders.ENABLE_DIGITAL_ZOOM, value)
						}
					/>
				);
			case SettingHeaders.ENABLE_SUPER_ZOOM:
				return (
					<div className="setting-msg-container">
						<ToggleButton
							value={
								settingsController.settings[SettingPageSectionHeaders.EXPERIMENTAL]?.children[
									SettingPageHeaders.ZOOM
								]?.children[SettingTabHeaders.GENERAL]?.children[SettingSectionHeaders.SUPERZOOM]
									?.children[SettingHeaders.ENABLE_SUPER_ZOOM]?.value
							}
							onChange={(value) =>
								settingsController.setSettingValue(SettingHeaders.ENABLE_SUPER_ZOOM, value)
							}
							disabled={!(window as any).superZoomSupported}
						/>
						{!(window as any).superZoomSupported && (
							<b className="setting-msg-container-disabled-text">
								{translationWithErrorHandling(intl, 'settings.robotDoesNotHaveSuperZoomEnabled')}
							</b>
						)}
					</div>
				);
			case SettingHeaders.MIRROR_PILOT_VIDEO_LOCALLY:
				return (
					<div className="setting-msg-container">
						<ToggleButton
							value={
								settingsController.settings[SettingPageSectionHeaders.APP_SETTINGS].children[
									SettingPageHeaders.AUDIOVIDEO
								]?.children[SettingTabHeaders.GENERAL]?.children[SettingSectionHeaders.AUDIOVIDEO]
									?.children[SettingHeaders.MIRROR_PILOT_VIDEO_LOCALLY]?.value
							}
							onChange={(value) =>
								settingsController.setSettingValue(SettingHeaders.MIRROR_PILOT_VIDEO_LOCALLY, value)
							}
						/>
						<div className="setting-msg-container-text">
							{
								settingsController.settings[SettingPageSectionHeaders.APP_SETTINGS].children[
									SettingPageHeaders.AUDIOVIDEO
								]?.children[SettingTabHeaders.GENERAL]?.children[SettingSectionHeaders.AUDIOVIDEO]
									?.children[SettingHeaders.MIRROR_PILOT_VIDEO_LOCALLY]?.description
							}
						</div>
					</div>
				);
		}
	};

	// Select the general tab of the setting page
	useMemo(() => {
		if (selectedSettingPage) {
			setSelectedTab(
				selectedSettingPage.children[SettingTabHeaders.GENERAL] ??
					Object.values(selectedSettingPage.children)[0]
			);
			if (tabContextRef?.current) (tabContextRef.current as any).scrollTo(0, 0);
		}
		return selectedSettingPage;
	}, [selectedSettingPage]);

	// Select the default setting page
	// useEffect(
	// 	() =>
	// 		setSelectedSettingPage(
	// 			settingsController.settings[SettingPageSectionHeaders.APP_SETTINGS].children[
	// 				SettingPageHeaders.APPEARANCE
	// 			]
	// 		),
	// 	[]
	// );

	const isUpdatingDevicesListRef = useRef(false);
	const [isUpdatingDevicesList, setIsUpdatingDevicesList] = useState(false);
	const updateDevicesList = useCallback(() => {
		if (isUpdatingDevicesListRef.current === true) return;

		isUpdatingDevicesListRef.current = true;
		setIsUpdatingDevicesList(isUpdatingDevicesListRef.current);

		navigator.mediaDevices
			.enumerateDevices()
			.then((mediaDevices) => {
				const microphones = mediaDevices
					.filter((device) => device.kind === 'audioinput')
					.map((device, i) => ({
						id: device.deviceId,
						name: device.label,
					}));

				const speakers = mediaDevices
					.filter((device) => device.kind === 'audiooutput')
					.map((device, i) => ({
						id: device.deviceId,
						name: device.label,
					}));

				const cameras = mediaDevices
					.filter((device) => device.kind === 'videoinput')
					.map((device, i) => ({
						id: device.deviceId,
						name: device.label,
					}));

				if (microphones.some((mic) => !mic.name) || cameras.some((cam) => !cam.name)) {
					// If any labels are empty, retry after 1 second
					// Labels will be empty if the user has not yet granted permission to access the devices
					setTimeout(updateDevicesList, 1000);
					return;
				}

				setMicrophonesList(microphones);
				setSpeakersList(speakers);
				setCamerasList(cameras);
			})
			.catch((error) => {
				console.error('updateDevicesList::Error enumerating media devices', error);
			})
			.finally(() => {
				isUpdatingDevicesListRef.current = false;
				setIsUpdatingDevicesList(isUpdatingDevicesListRef.current);
			});
	}, []);

	// update the device list when the available media devices change - eg: when user plugs in a new device
	useEffect(() => {
		updateDevicesList();
		navigator.mediaDevices.addEventListener('devicechange', updateDevicesList);
		return () => navigator.mediaDevices.removeEventListener('devicechange', updateDevicesList);
	}, [updateDevicesList]);

	return settingsController.isOpen ? (
		<div className="settingsContainer">
			<div className="settings" ref={innerContainerRef}>
				<div className="settingsMenuContainer">
					<div className="settingsMenuContext">
						<div className="settingsMenuHeader">
							<BetaLabel />
						</div>
						<div className="settingsMenu">
							{Object.values(settingsController.settings)
								.filter((pageSection) => !pageSection.hideHeader)
								.map((pageSection) => (
									<div key={`page-section-${pageSection.header}`} className="pageSection">
										<div className="settingSectionHeaderSidebar">
											{(() => {
												switch (pageSection.header) {
													case SettingPageSectionHeaders.APP_SETTINGS:
														return translationWithErrorHandling(
															intl,
															'settings.appSettings'
														).toUpperCase();
													case SettingPageSectionHeaders.EXPERIMENTAL:
														return intl
															.formatMessage({ id: 'settings.experimental' })
															.toUpperCase();
													case SettingPageSectionHeaders.ADMIN:
														return translationWithErrorHandling(
															intl,
															'settings.admin'
														).toUpperCase();
													default:
														return 'MISSING TRANSLATION';
												}
											})()}
										</div>
										<div className="pageSectionPages">
											{Object.entries(pageSection.children).map(([pageHeader, page]) => (
												<div
													key={`page-${pageHeader}`}
													className={`settingsHandleButton${
														pageSection.header === selectedSettingPageSection?.header &&
														pageHeader === selectedSettingPage?.header
															? 'Selected'
															: ''
													}`}
													onClick={() => onPageClick(pageSection, page)}
												>
													{(() => {
														switch (pageHeader) {
															case SettingPageHeaders.APPEARANCE:
																return translationWithErrorHandling(intl, 'settings.appearance');
															case SettingPageHeaders.ZOOM:
																return translationWithErrorHandling(intl, 'settings.zoom');
															case SettingPageHeaders.AUGMENTED_REALITY:
																return translationWithErrorHandling(
																	intl,
																	'settings.augmentedReality'
																);
															case SettingPageHeaders.URL_SHARING:
																return translationWithErrorHandling(intl, 'settings.urlSharing');
															case SettingPageHeaders.AUDIOVIDEO:
																return translationWithErrorHandling(intl, 'settings.audioVideo');
															case SettingPageHeaders.FEEDBACK:
																return translationWithErrorHandling(intl, 'settings.feedback');
															case SettingPageHeaders.IMAGE_RECOGNITION:
																return translationWithErrorHandling(
																	intl,
																	'settings.imageRecognition'
																);
															case SettingPageHeaders.TOOLS:
																return translationWithErrorHandling(intl, 'settings.tools');
															case SettingPageHeaders.MISC:
																return translationWithErrorHandling(intl, 'settings.misc');
															default:
																return 'MISSING TRANSLATION';
														}
													})()}
												</div>
											))}
										</div>
									</div>
								))}
						</div>
					</div>
					<div className="closeButton" onClick={() => settingsController.toggle()}>
						{translationWithErrorHandling(intl, 'settings.close')}
					</div>
				</div>
				<div className="settingsContext">
					{!selectedSettingPageSection.hideHeader ? (
						<>
							<div className="tabSection">
								{selectedSettingPage &&
									Object.entries(selectedSettingPage?.children).map(([tabHeader, tab]) => (
										<div
											key={`tab-${tabHeader}`}
											className={`settingsHandleButton${
												tabHeader === selectedTab?.header ? 'Selected' : ''
											}`}
											onClick={() => onTabClick(tab)}
										>
											{(() => {
												switch (tabHeader) {
													case SettingTabHeaders.GENERAL:
														return translationWithErrorHandling(intl, 'settings.generalTab');
													case SettingTabHeaders.OBJECT_DETECTION:
														return translationWithErrorHandling(
															intl,
															'settings.objectDetectionTab'
														);
													case SettingTabHeaders.OBJECT_SEGMENTATION:
														return translationWithErrorHandling(
															intl,
															'settings.objectSegmentationTab'
														);
													case SettingTabHeaders.GESTURE_DETECTION:
														return translationWithErrorHandling(intl, 'settings.gestureTab');
													default:
														return 'MISSING TRANSLATION';
												}
											})()}
										</div>
									))}
							</div>
							<div className="tabContext" ref={tabContextRef}>
								{selectedTab?.children &&
									Object.entries(selectedTab?.children).map(([sectionHeader, section]) => (
										<div key={`tab-section-${sectionHeader}`} className="settingSection">
											{!section.hideHeader ? (
												<div className="settingSectionHeader">
													{(() => {
														switch (sectionHeader) {
															case SettingSectionHeaders.RESET:
																return translationWithErrorHandling(intl, 'settings.resetSettings');
															case SettingSectionHeaders.AUGMENTED_REALITY:
																return translationWithErrorHandling(
																	intl,
																	'settings.augmentedRealitySettings'
																);
															case SettingSectionHeaders.URL_SHARING:
																return translationWithErrorHandling(
																	intl,
																	'settings.urlSharingSettings'
																);
															case SettingSectionHeaders.SUPERZOOM:
																return translationWithErrorHandling(
																	intl,
																	'settings.superZoomSettings'
																);
															case SettingSectionHeaders.AUDIOVIDEO:
																return translationWithErrorHandling(
																	intl,
																	'settings.audioVideoSettings'
																);
															case SettingSectionHeaders.FEEDBACKS:
																return translationWithErrorHandling(
																	intl,
																	'settings.feedbacksSettings'
																);
															case SettingSectionHeaders.DETECTION:
																return translationWithErrorHandling(
																	intl,
																	'settings.detectionSettings'
																);
															case SettingSectionHeaders.SEGMENTATION:
																return translationWithErrorHandling(
																	intl,
																	'settings.segmentationSettings'
																);
															case SettingSectionHeaders.MISC:
																return translationWithErrorHandling(intl, 'settings.miscSettings');
															default:
																return 'MISSING TRANSLATION';
														}
													})()}
												</div>
											) : null}
											<div className="settingsWrapper">
												{Object.entries(section.children).map(([settingHeader, setting]) => (
													<div key={`setting-${settingHeader}`} className="setting">
														{!setting.hideHeader ? (
															<div className="settingHeader">
																{(() => {
																	switch (settingHeader) {
																		case SettingHeaders.RESET:
																			return translationWithErrorHandling(
																				intl,
																				'settings.resetHeader'
																			);
																		case SettingHeaders.AR_ENABLE_NAV_LINES:
																			return translationWithErrorHandling(
																				intl,
																				'settings.arEnableNavLinesHeader'
																			);
																		case SettingHeaders.URL_SHARING_ENABLED:
																			return translationWithErrorHandling(
																				intl,
																				'settings.urlSharingEnabledHeader'
																			);
																		case SettingHeaders.FEEDBACK_GENERAL:
																			return translationWithErrorHandling(
																				intl,
																				'settings.feedbackGeneralHeader'
																			);
																		case SettingHeaders.FEEDBACK_SESSION_END:
																			return translationWithErrorHandling(
																				intl,
																				'settings.feedbackSessionEndHeader'
																			);
																		case SettingHeaders.FEEDBACK_SWITCH:
																			return translationWithErrorHandling(
																				intl,
																				'settings.feedbackSwitchHeader'
																			);
																		case SettingHeaders.ENABLE_EXPERIMENTAL:
																			return translationWithErrorHandling(
																				intl,
																				'settings.enableExperimentalHeader'
																			);
																		case SettingHeaders.DRAG_MODE:
																			return translationWithErrorHandling(
																				intl,
																				'settings.dragModeHeader'
																			);
																		case SettingHeaders.OBJECT_DETECTION_LIBRARY:
																			return translationWithErrorHandling(
																				intl,
																				'settings.objectDetectionLibraryHeader'
																			);
																		case SettingHeaders.ENABLE_OBJECT_DETECTION:
																			return translationWithErrorHandling(
																				intl,
																				'settings.enableObjectDetectionHeader'
																			);
																		case SettingHeaders.ENABLE_GESTURE_DETECTION:
																			return translationWithErrorHandling(
																				intl,
																				'settings.enableGestureDetectionHeader'
																			);
																		case SettingHeaders.ENABLE_GESTURE_LANDMARKS_OVERLAY:
																			return translationWithErrorHandling(
																				intl,
																				'settings.enableGestureLandmarksOverlayHeader'
																			);
																		case SettingHeaders.ENABLE_OBJECT_DETECTION_FACE_TRACKING:
																			return translationWithErrorHandling(
																				intl,
																				'settings.enableObjectDetectionFaceTrackingHeader'
																			);
																		case SettingHeaders.OBJECT_SEGMENTATION_LIBRARY:
																			return translationWithErrorHandling(
																				intl,
																				'settings.objectSegmentationLibraryHeader'
																			);
																		case SettingHeaders.ENABLE_SELFIE_SEGMENTATION:
																			return translationWithErrorHandling(
																				intl,
																				'settings.enableSelfieSegmentationHeader'
																			);
																		case SettingHeaders.ENABLE_ADMIN:
																			return translationWithErrorHandling(
																				intl,
																				'settings.enableAdminHeader'
																			);
																		case SettingHeaders.ENABLE_CAMERA_CALIBRATION_TOOL:
																			return translationWithErrorHandling(
																				intl,
																				'settings.enableCameraCalibrationToolHeader'
																			);
																		case SettingHeaders.STAT_MODE:
																			return translationWithErrorHandling(
																				intl,
																				'settings.statModeHeader'
																			);
																		case SettingHeaders.MOTION_ENCODERS:
																			return translationWithErrorHandling(
																				intl,
																				'settings.useEncoderPresetsOptimizedForMotionHeader'
																			);
																		case SettingHeaders.BITRATE_TO_REQUEST:
																			return translationWithErrorHandling(
																				intl,
																				'settings.bitratePresetToRequestFromRobotHeader'
																			);
																		case SettingHeaders.MICROPHONE:
																			return translationWithErrorHandling(
																				intl,
																				'settings.microphoneHeader'
																			);
																		case SettingHeaders.SPEAKERS:
																			return translationWithErrorHandling(
																				intl,
																				'settings.speakersHeader'
																			);
																		case SettingHeaders.CAMERA:
																			return translationWithErrorHandling(
																				intl,
																				'settings.cameraHeader'
																			);
																		case SettingHeaders.MIRROR_PILOT_VIDEO_LOCALLY:
																			return translationWithErrorHandling(
																				intl,
																				'settings.mirrorPilotVideoLocallyHeader'
																			);
																		case SettingHeaders.AR_INVERT_BACKWARD_NAV_LINES:
																			return translationWithErrorHandling(
																				intl,
																				'settings.arInvertBackwardNavLinesHeader'
																			);
																		case SettingHeaders.NAV_SPEED:
																			return translationWithErrorHandling(
																				intl,
																				'settings.navSpeedHeader'
																			);
																		case SettingHeaders.LOCAL_VOICE_VOLUME:
																			return translationWithErrorHandling(
																				intl,
																				'settings.localVoiceVolumeHeader'
																			);
																		case SettingHeaders.ENABLE_DIGITAL_ZOOM:
																			return translationWithErrorHandling(
																				intl,
																				'settings.enableDigitalZoomHeader'
																			);
																		case SettingHeaders.ENABLE_SUPER_ZOOM:
																			return translationWithErrorHandling(
																				intl,
																				'settings.enableSuperZoomHeader'
																			);
																		default:
																			return 'MISSING TRANSLATION';
																	}
																})()}
															</div>
														) : null}
														<div className="settingContainer">
															{renderSetting(setting, {
																reset: () => settingsController.reset(selectedTab),
															})}
														</div>
													</div>
												))}
											</div>
										</div>
									))}
							</div>
						</>
					) : null}
				</div>
			</div>
		</div>
	) : null;
};

export default reduxConnector(Settings);
