import {
	PilotPrimaryCamera,
	RobotNavCamera,
	RobotPrimaryCamera,
	SessionType,
} from 'GoBeWebRTC/types';
import { downloadBlob, remoteTracksMidsMap } from 'GoBeWebRTC/utils';
import { AppContext } from 'context/appContext';
import {
	SettingHeaders,
	SettingPageHeaders,
	SettingPageSectionHeaders,
	SettingSectionHeaders,
	SettingTabHeaders,
} from 'hooks/useSettingsController';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';

export enum StatMode {
	DEFAULT = 'Default',
	INTENSIVE = 'Intensive',
}

type SessionSwitchStats = PeerConnectionSwitchStat[];

type PeerConnectionSwitchStat = {
	peerConnectionId: number;
	candidatePairs: {
		local: RTCIceCandidate;
		remote: RTCIceCandidate;
	};
	transceiverSwitchStats: TransceiverSwitchStat[];
};

type TransceiverSwitchStat = {
	mid: string;
	kind: 'video' | 'audio';
	switchStats: SwitchStat[];
};

type ParsedTransceiverSwitchStat = {
	mid: string;
	lastRtcStatsReport: any;
	frameStack: any;
	currentSwitchStats: SwitchStatTimestamp;
	statsId: number;
};

type SwitchStat = {
	id: number;
	timestamps: SwitchStatTimestamp;
	intervals: {
		lostFramesToIceRestart?: number;
		iceRestartToLostFrames?: number;
		iceRestartAtToGainedFramesAt: number;
		lostFramesAtToGainedFramesAt: number;
		gainedFramesAtToStableFramesAt?: number;
		lostFramesAtToStableFramesAt?: number;
	};
	status: 'succeeded' | 'failed';
};

type SwitchStatTimestamp = {
	lostFramesAt: number | null;
	iceRestartAt: number | null;
	gainedFramesAt: number | null;
	stableFramesAt: number | null;
};

let prevTimeStampWide = 0;
let prevTimeStampNav = 0;
let prevDecodedFramesWide = 0;
let prevDecodedFramesNav = 0;
let prevFramesReceivedWide = 0;
let prevFramesReceivedNav = 0;
let prevProcessingDelayWide = 0;
let prevProcessingDelayNav = 0;
let prevPacketsReceived = [0, 0, 0, 0, 0];
let prevPacketsLost = [0, 0, 0, 0, 0];
let freqDividerCounter = 0;
function ringbufferSubsection(buffer: any[], start: number, end: number): any[] {
	if (start <= end) {
		// If the start index is less than or equal to the end index, just slice the array normally
		return buffer.slice(start, end);
	} else {
		// If the start index is greater than the end index, we need to slice from start to the end of the array
		// and from the start of the array to the end index, then concatenate the two slices
		return buffer.slice(start).concat(buffer.slice(0, end));
	}
}

const PACKET_LOSS_HISTORY_SIZE = 10; // We show packet loss as an average across the recent past, but full sparkline buffer is to large
export const MAX_STATS_COUNT = 30;

export function useStats(
	peerConnection: RTCPeerConnection,
	sessionInfo: { robot: string; environment: string; sessionId: string; sessionType: SessionType },
	onSwitchStatCallback: (stat: any) => any
) {
	const { settingsController } = useContext(AppContext);
	const [mode, setMode] = useState<StatMode>(
		sessionInfo.sessionType === 'testing' ? StatMode.INTENSIVE : StatMode.DEFAULT
	);
	const modeRef = useRef<StatMode>(mode);
	const defaultSampleRate = modeRef.current === StatMode.INTENSIVE ? 100 : 1000;
	const stableFramesPerSecond = 20;
	const intervalIdRef = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
	const sessionSwitchStatsRef = useRef<SessionSwitchStats>([]);
	const peerConnectionIdRef = useRef<number>(0);
	const transceiverSwitchStatsRef = useRef<ParsedTransceiverSwitchStat[]>([]);
	const switchStatInit: SwitchStatTimestamp = {
		lostFramesAt: null,
		iceRestartAt: null,
		gainedFramesAt: null,
		stableFramesAt: null,
	};

	/**
	 * Contains additional network info data using circular buffer of MAX_STATS_COUNT length
	 * data > circular buffer
	 * currPointer > current pointer for circular buffer
	 */
	const statsRef = useRef<any>({
		inboundRTP: {
			[RobotPrimaryCamera.WIDE_CAM]: {
				fps: { data: new Array(MAX_STATS_COUNT).fill(0) },
				bitRate: { data: new Array(MAX_STATS_COUNT).fill(0), prevTimeStamp: 0, prevByteCount: 0 },
				packetsReceived: { data: new Array(MAX_STATS_COUNT).fill(0) },
				packetLoss: { data: new Array(MAX_STATS_COUNT).fill(0) },
				packetLossPercent: { data: new Array(MAX_STATS_COUNT).fill(0) },
				currPointer: -1,
				isFilled: false,
			},
			[RobotNavCamera.NAV_CAM]: {
				fps: { data: new Array(MAX_STATS_COUNT).fill(0) },
				bitRate: { data: new Array(MAX_STATS_COUNT).fill(0), prevTimeStamp: 0, prevByteCount: 0 },
				packetsReceived: { data: new Array(MAX_STATS_COUNT).fill(0) },
				packetLoss: { data: new Array(MAX_STATS_COUNT).fill(0) },
				packetLossPercent: { data: new Array(MAX_STATS_COUNT).fill(0) },
				currPointer: -1,
				isFilled: false,
			},
			audio: {
				bitRate: { data: new Array(MAX_STATS_COUNT).fill(0), prevTimeStamp: 0, prevByteCount: 0 },
				packetsReceived: { data: new Array(MAX_STATS_COUNT).fill(0) },
				packetLoss: { data: new Array(MAX_STATS_COUNT).fill(0) },
				packetLossPercent: { data: new Array(MAX_STATS_COUNT).fill(0) },
				currPointer: -1,
				isFilled: false,
			},
		},
		outboundRTP: {
			[PilotPrimaryCamera.LOCAL]: {
				fps: { data: new Array(MAX_STATS_COUNT).fill(0) },
				bitRate: { data: new Array(MAX_STATS_COUNT).fill(0), prevTimeStamp: 0, prevByteCount: 0 },
				currPointer: -1,
				isFilled: false,
			},
			audio: {
				bitRate: { data: new Array(MAX_STATS_COUNT).fill(0), prevTimeStamp: 0, prevByteCount: 0 },
				currPointer: -1,
				isFilled: false,
			},
		},
		succeededCandidatePair: undefined,
	});

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const stats = useMemo(() => statsRef.current, [statsRef.current]);
	const stableFramesCallbacksRef = useRef<(() => any)[]>([]);

	// Parse switch stat
	const parseSwitchStat: (
		transceiverSwitchStats: ParsedTransceiverSwitchStat,
		isVideo: boolean
	) => SwitchStat = (transceiverSwitchStats, isVideo) => {
		const { lostFramesAt, iceRestartAt, gainedFramesAt, stableFramesAt } =
			transceiverSwitchStats!.currentSwitchStats;
		const iceRestartFirst = lostFramesAt! > iceRestartAt!;
		return {
			id: transceiverSwitchStats?.statsId!,
			timestamps: transceiverSwitchStats?.currentSwitchStats,
			intervals: {
				[iceRestartFirst ? 'iceRestartToLostFrames' : 'lostFramesToIceRestart']:
					(iceRestartFirst ? 1 : -1) * (lostFramesAt! - iceRestartAt!),
				iceRestartAtToGainedFramesAt: gainedFramesAt! - iceRestartAt!,
				lostFramesAtToGainedFramesAt: gainedFramesAt! - lostFramesAt!,
				...(isVideo
					? {
							gainedFramesAtToStableFramesAt: stableFramesAt! - gainedFramesAt!,
							lostFramesAtToStableFramesAt: stableFramesAt! - lostFramesAt!,
					  }
					: {}),
			},
			status: stableFramesAt ? 'succeeded' : 'failed',
		};
	};

	// Add switch stat to overall session switch stat
	const addSessionStat: (mid: string, kind: 'audio' | 'video', stat: SwitchStat) => void = (
		mid,
		kind,
		stat
	) => {
		if (modeRef.current === StatMode.INTENSIVE) {
			const peerConnectionStats = sessionSwitchStatsRef.current.find(
				(value) => value.peerConnectionId === peerConnectionIdRef.current
			);
			if (peerConnectionStats) {
				const transceiverSwitchStat = peerConnectionStats.transceiverSwitchStats.find(
					(t) => t.mid === mid
				);
				if (transceiverSwitchStat) transceiverSwitchStat.switchStats.push(stat);
				else
					peerConnectionStats.transceiverSwitchStats.push({
						mid,
						kind,
						switchStats: [stat],
					});
			} else {
				sessionSwitchStatsRef.current.push({
					peerConnectionId: peerConnectionIdRef.current,
					candidatePairs: {
						local: statsRef.current?.succeededCandidatePair?.local!,
						remote: statsRef.current?.succeededCandidatePair?.remote!,
					},
					transceiverSwitchStats: [{ mid, kind, switchStats: [stat] }],
				});
			}
		}
	};

	// Callback when getting rtc stats report from the browser peer connection
	const onRTCStatsReport = (rtcStatsReport: any) => {
		let statsReportJson = {
			data: {
				widecam: {
					frames_received_per_second: 0,
					frames_decoded_per_second: 0,
					us_processing_delay_per_second: 0,
				},
				navcam: {
					frames_received_per_second: 0,
					frames_decoded_per_second: 0,
					us_processing_delay_per_second: 0,
				},
			},
		};

		rtcStatsReport.forEach((report: any) => {
			switch (report.type) {
				case 'candidate-pair':
					if (report.nominated && report.state === 'succeeded') {
						const succeededCandidatePair: any = {
							local: rtcStatsReport.get(report.localCandidateId),
							remote: rtcStatsReport.get(report.remoteCandidateId),
						};
						let scopedFields = ['candidateType', 'relayProtocol', 'protocol'];
						if (mode === StatMode.INTENSIVE)
							scopedFields = scopedFields.concat(['address', 'port']);
						if (
							!(
								statsRef.current?.succeededCandidatePair &&
								scopedFields.every((key) =>
									['local', 'remote'].every(
										(type) =>
											succeededCandidatePair[type][key] ===
											statsRef.current?.succeededCandidatePair?.[type][key]
									)
								)
							)
						)
							statsRef.current.succeededCandidatePair = succeededCandidatePair;
					}
					break;
				case 'inbound-rtp':
					if (report.isRemote) {
						return;
					}

					if (report.kind === 'video') {
						if (report.frameWidth === 1280 && report.frameHeight === 720) {
							let timeSinceLastFrame = (report.timestamp - prevTimeStampWide) / 1000;
							prevTimeStampWide = report.timestamp;
							let decodedFrames = report.framesDecoded - prevDecodedFramesWide;
							prevDecodedFramesWide = report.framesDecoded;
							let framesReceived = report.framesReceived - prevFramesReceivedWide;
							prevFramesReceivedWide = report.framesReceived;
							let totalProcessingDelay = report.totalProcessingDelay - prevProcessingDelayWide;
							prevProcessingDelayWide = report.totalProcessingDelay;

							statsReportJson.data.widecam.frames_received_per_second = Math.ceil(
								framesReceived / timeSinceLastFrame
							);
							statsReportJson.data.widecam.frames_decoded_per_second = Math.ceil(
								decodedFrames / timeSinceLastFrame
							);
							statsReportJson.data.widecam.us_processing_delay_per_second = Math.ceil(
								totalProcessingDelay * 1000
							);
						} else if (report.frameWidth === 320 && report.frameHeight === 240) {
							let timeSinceLastFrame = (report.timestamp - prevTimeStampNav) / 1000;
							prevTimeStampNav = report.timestamp;
							let framesReceived = report.framesReceived - prevFramesReceivedNav;
							prevFramesReceivedNav = report.framesReceived;
							let totalProcessingDelay = report.totalProcessingDelay - prevProcessingDelayNav;
							prevProcessingDelayNav = report.totalProcessingDelay;
							let decodedFrames = report.framesDecoded - prevDecodedFramesNav;
							prevDecodedFramesNav = report.framesDecoded;

							statsReportJson.data.navcam.frames_received_per_second = Math.ceil(
								framesReceived / timeSinceLastFrame
							);
							statsReportJson.data.navcam.frames_decoded_per_second = Math.ceil(
								decodedFrames / timeSinceLastFrame
							);
							statsReportJson.data.navcam.us_processing_delay_per_second = Math.ceil(
								totalProcessingDelay * 1000
							);
						} else {
							console.log('Unable to parse stats for incoming video stream');
							return;
						}
					}

					// Get lastRtcStatsReport from transceiver switch stats
					const transceiverSwitchStats = transceiverSwitchStatsRef.current.find(
						(t) => t.mid === report.mid
					);
					if (transceiverSwitchStats && transceiverSwitchStats.lastRtcStatsReport) {
						const isVideo = report.kind === 'video';
						// calculate number of frames
						const keyFieldName = isVideo ? 'framesReceived' : 'packetsReceived';
						let framesPerUnit =
							report[keyFieldName] - transceiverSwitchStats?.lastRtcStatsReport[keyFieldName];
						// Add  frames per unit to frame stack
						if (transceiverSwitchStats?.frameStack)
							transceiverSwitchStats?.frameStack.push(report.timestamp, framesPerUnit);
						// Handle stable frames callbacks
						if (
							isVideo &&
							stableFramesCallbacksRef.current.length &&
							transceiverSwitchStats?.frameStack.isStable()
						) {
							let stableFramesCallback;
							do {
								stableFramesCallback = stableFramesCallbacksRef.current.shift();
								if (stableFramesCallback) stableFramesCallback();
							} while (stableFramesCallback);
						}
						// Do nothing if there is no iceRestart registered
						if (transceiverSwitchStats?.currentSwitchStats?.iceRestartAt) {
							// If there are no new frames, register the timestamp if not registered already and do nothing
							if (framesPerUnit === 0) {
								if (!transceiverSwitchStats?.currentSwitchStats?.lostFramesAt)
									transceiverSwitchStats.currentSwitchStats = {
										...transceiverSwitchStats?.currentSwitchStats!,
										lostFramesAt: transceiverSwitchStats?.lastRtcStatsReport.timestamp,
									};
								transceiverSwitchStats.currentSwitchStats = {
									...transceiverSwitchStats?.currentSwitchStats!,
									gainedFramesAt: null,
								};
							}
							// If theres new frames coming in
							else if (
								framesPerUnit > 0 &&
								transceiverSwitchStats?.currentSwitchStats?.lostFramesAt
							) {
								// Do nothing but registering gained frames timestamp and empty out framestack If we havent done it previously
								if (!transceiverSwitchStats?.currentSwitchStats?.gainedFramesAt) {
									// Empty out frame stack
									transceiverSwitchStats.frameStack.data = [
										transceiverSwitchStats?.frameStack.data[
											transceiverSwitchStats?.frameStack.data.length - 1
										],
									];
									transceiverSwitchStats.currentSwitchStats = {
										...transceiverSwitchStats?.currentSwitchStats,
										gainedFramesAt: transceiverSwitchStats?.lastRtcStatsReport.timestamp,
									};
								}
								// Do nothing If we registered stable frames timestamp already OR frame stack is not full OR sum of frames in framestack are less than required frames per second to qualify as stable
								else if (
									!transceiverSwitchStats?.currentSwitchStats?.stableFramesAt &&
									(!isVideo || transceiverSwitchStats?.frameStack.isStable())
								) {
									transceiverSwitchStats.currentSwitchStats = {
										...transceiverSwitchStats?.currentSwitchStats,
										stableFramesAt: report.timestamp,
									};
									const stat: SwitchStat = parseSwitchStat(transceiverSwitchStats, isVideo);
									// Call switch callback
									if (onSwitchStatCallback)
										onSwitchStatCallback({ mid: report.mid!, kind: report.kind, ...stat });
									addSessionStat(report.mid!, report.kind, stat);
									transceiverSwitchStats.statsId++;
									transceiverSwitchStats.currentSwitchStats = { ...switchStatInit };
								}
							}
						}
					}

					transceiverSwitchStats!.lastRtcStatsReport = report;

					if (remoteTracksMidsMap[report.mid]) {
						let inboundRTPStats = statsRef.current.inboundRTP[remoteTracksMidsMap[report.mid]];
						inboundRTPStats.currPointer++;
						if (inboundRTPStats.currPointer >= MAX_STATS_COUNT) {
							inboundRTPStats.currPointer = 0;
							inboundRTPStats.isFilled = true;
						}
						if (report.kind !== 'audio') {
							inboundRTPStats.fps.data[inboundRTPStats.currPointer] = (
								report.framesPerSecond || 0
							).toFixed(0);
						}
						inboundRTPStats.bitRate.data[inboundRTPStats.currPointer] = (
							(8 * (report.bytesReceived / 1000 - inboundRTPStats.bitRate.prevByteCount)) /
							((report.timestamp - (inboundRTPStats.bitRate.prevTimeStamp || 0)) / 1000)
						).toFixed(0);
						inboundRTPStats.bitRate.prevTimeStamp = report.timestamp;
						inboundRTPStats.bitRate.prevByteCount = report.bytesReceived / 1000;
						inboundRTPStats.packetsReceived.data[inboundRTPStats.currPointer] = (
							report.packetsReceived - prevPacketsReceived[report.mid] || 0
						).toFixed(0);
						prevPacketsReceived[report.mid] = report.packetsReceived || 0;
						inboundRTPStats.packetLoss.data[inboundRTPStats.currPointer] = Math.max(
							(report.packetsLost || 0) - prevPacketsLost[report.mid],
							0
						).toFixed(0);
						prevPacketsLost[report.mid] = report.packetsLost;

						const packetsReceivedInHistory = ringbufferSubsection(
							inboundRTPStats.packetsReceived.data,

							(inboundRTPStats.currPointer + (MAX_STATS_COUNT - PACKET_LOSS_HISTORY_SIZE)) %
								MAX_STATS_COUNT,
							inboundRTPStats.currPointer
						).reduce((prev: string, curr: string) => Number(prev) + Number(curr), 0);
						const packetsLostInHistory = ringbufferSubsection(
							inboundRTPStats.packetLoss.data,

							(inboundRTPStats.currPointer + (MAX_STATS_COUNT - PACKET_LOSS_HISTORY_SIZE)) %
								MAX_STATS_COUNT,
							inboundRTPStats.currPointer
						).reduce((prev: string, curr: string) => Number(prev) + Number(curr), 0);

						const avgPacketLoss: number = packetsLostInHistory
							? (packetsLostInHistory / packetsReceivedInHistory) * 100
							: 0;
						inboundRTPStats.packetLossPercent.data[inboundRTPStats.currPointer] =
							avgPacketLoss.toFixed(2);
					}
					break;
				case 'outbound-rtp':
					if (report.isRemote) {
						return;
					}

					if (report.kind === 'video' || report.kind === 'audio') {
						let outboundRTPStats =
							statsRef.current.outboundRTP[
								report.kind === 'video' ? PilotPrimaryCamera.LOCAL : 'audio'
							];
						outboundRTPStats.currPointer++;
						if (outboundRTPStats.currPointer >= MAX_STATS_COUNT) {
							outboundRTPStats.currPointer = 0;
							outboundRTPStats.isFilled = true;
						}
						if (report.kind !== 'audio') {
							outboundRTPStats.fps.data[outboundRTPStats.currPointer] = (
								report.framesPerSecond || 0
							).toFixed(0);
						}
						outboundRTPStats.bitRate.data[outboundRTPStats.currPointer] = (
							(8 * (report.bytesSent / 1000 - outboundRTPStats.bitRate.prevByteCount)) /
							((report.timestamp - (outboundRTPStats.bitRate.prevTimeStamp || 0)) / 1000)
						).toFixed(0);
						outboundRTPStats.bitRate.prevTimeStamp = report.timestamp;
						outboundRTPStats.bitRate.prevByteCount = report.bytesSent / 1000;
					}

					break;
			}
		});

		// Rate of stats report should always be once per second
		if (
			(modeRef.current === StatMode.INTENSIVE && freqDividerCounter % 10 === 0) ||
			modeRef.current === StatMode.DEFAULT
		) {
			window.dispatchEvent(
				new CustomEvent('sendWebrtcStatsReport', { detail: statsReportJson.data })
			);
			freqDividerCounter++;
		}
	};

	// Callback method to mark the start of the GoBe switch event
	const captureGoBeSwitchStats = () => {
		transceiverSwitchStatsRef.current.forEach((t) => {
			if (t.lastRtcStatsReport && t.currentSwitchStats?.iceRestartAt) {
				const { mid, kind } = t.lastRtcStatsReport,
					stat = parseSwitchStat(t, kind === 'video');

				// Call switch callback
				if (onSwitchStatCallback)
					onSwitchStatCallback({
						mid,
						kind,
						...stat,
					});
				addSessionStat(mid, kind, stat);
			}
			t.currentSwitchStats = {
				...switchStatInit,
				iceRestartAt: +new Date(),
			};
		});
	};

	// Handle stat mode initialization and changes
	useMemo(() => {
		// Do nothing if theres no peer connection
		if (!peerConnection) return;

		// Set the mode
		modeRef.current = mode;

		// Remove previous get stats loop
		clearInterval(intervalIdRef.current!);

		// Calculate sample rate based on mode
		const sampleRate = modeRef.current === StatMode.INTENSIVE ? 100 : 1000;

		// Foreach transceiver stats
		transceiverSwitchStatsRef.current.forEach((t) => {
			// Reinitialize stats and counters
			t.currentSwitchStats = switchStatInit;
			t.statsId = 1;

			// Empty the framestack and reset the limit
			const frameStack = t.frameStack;
			frameStack.data = [];
			frameStack.limit = Math.floor(1000 / sampleRate);
		});

		// Start get stats loop
		intervalIdRef.current = setInterval(async () => {
			const report = await peerConnection?.getStats();
			if (report) onRTCStatsReport(report);
		}, sampleRate);

		// Expose switch stats if in Intensive mode
		if (!(window as any).getSwitchStats && modeRef.current === StatMode.INTENSIVE)
			(window as any).getSwitchStats = (download = false) => {
				const value = {
					...sessionInfo,
					switchStats: sessionSwitchStatsRef.current,
				};
				if (download)
					downloadBlob(
						JSON.stringify(value),
						`switch_stats_${sessionInfo.sessionId}`,
						'application/json'
					);
				return value;
			};

		return modeRef.current;
	}, [mode, peerConnection]);

	// Increment peer connection id
	useEffect(() => {
		if (peerConnection) {
			transceiverSwitchStatsRef.current = [];
			peerConnection.ontrack = (e: RTCTrackEvent) => {
				const { transceiver: t } = e;
				transceiverSwitchStatsRef.current.push({
					mid: t.mid!,
					lastRtcStatsReport: null,
					frameStack: {
						data: [],
						limit: Math.floor(1000 / defaultSampleRate),
						push: function (timestamp: any, frames: any) {
							this.data.push({ timestamp, frames });
							if (this.data.length > this.limit) this.data.shift();
						},
						sum: function () {
							return this.data.reduce((prev: any, curr: any) => prev + curr.frames, 0);
						},
						isFull: function () {
							return this.data.length === this.limit;
						},
						isStable: function () {
							return this.isFull() && this.sum() >= stableFramesPerSecond;
						},
					} as any,
					currentSwitchStats: switchStatInit,
					statsId: 1,
				});
			};
			peerConnectionIdRef.current++;
		}
	}, [peerConnection]);

	// Clear get stat loop on unmount
	useEffect(() => {
		return () => {
			clearInterval(intervalIdRef.current!);
			intervalIdRef.current = undefined;
		};
	}, []);

	// Use stat mode
	const statMode = useMemo(
		() =>
			settingsController.settings[SettingPageSectionHeaders.ADMIN].children[
				SettingPageHeaders.TOOLS
			].children[SettingTabHeaders.GENERAL].children[SettingSectionHeaders.MISC].children[
				SettingHeaders.STAT_MODE
			].value,
		[settingsController.settings]
	);
	useMemo(() => {
		setMode(statMode);
		return statMode;
	}, [statMode]);

	// Expose stats for automation purposes
	(window as any).transceiverSwitchStats = transceiverSwitchStatsRef.current;
	(window as any).sessionSwitchStats = sessionSwitchStatsRef.current;

	return {
		stats,
		captureGoBeSwitchStats,
		addStableFramesCallback: (callback: () => any) =>
			stableFramesCallbacksRef.current.push(callback),
	};
}
